.page {
  .entry {
    margin-bottom: 3rem;
    h2 {
      font-size: 2.0625rem;
      color: $color-principal;
      font-weight: bold;
      margin-bottom: 1.5rem;
      @media screen and (max-width: 991px) {
        margin-bottom: .7rem;
      }
    }
    h3 {
      font-size: 1.6rem;
      color: $color-secundario;
      font-weight: 500;
      margin-bottom: 1.5rem;
      width: 70%;
      margin-top: 2rem;
      @media screen and (max-width: 767px) {
        font-size: 1.4rem;
      }
    }
    p {
      padding-left: 4rem;
      @media screen and (max-width: 991px) {
        padding-left: 0rem;
      }
    }
    ul {
      list-style: none;
      padding-left: 4rem;
      @media screen and (max-width: 991px) {
        padding-left: 2rem;
      }
      @media screen and (max-width: 767px) {
        padding-left: 1rem;
      }
    }
    li {
      padding-left: 1.8rem;
      position: relative;
      margin-bottom: 8px;
      &:before {
        left: 0;
        position: absolute;
        font-family: "FontAwesome";
        content: "\f105";
        font-weight: bold;
        color: $color-alternativo;
        padding-right: 15px;
        @media screen and (max-width: 767px) {
          padding-right: 8px;
        }
      }
    }
  }
}



.post {
  &__entry {
    p, ul, ol {
      margin-bottom: 2.5rem;
      font-size: 1.2rem;
      line-height: 1.7;
      color: $color-gris-03;
    }
  }
}