.page-title {
  background-color: $color-principal;
  padding: 3.2rem 0;
  @media screen and (max-width: 991px) {
    padding: 0 0 1.25rem 0;
  }
  @media screen and (max-width: 575px) {
    padding: 1rem 0 1.2rem 0;
  }
  &__h {
    color: $color-background;
    font-size: 4rem;
    font-weight: bold;
    
    @media screen and (max-width: 991px) {
      font-size: 3.2rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
      margin-bottom: 3px;
    }
  }
  &__breadcrumbs {
    color: $color-background;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      color: rgba(255, 255, 255, 0.534);
    }
    a {
      &:hover {
        color: $color-terciario;
      }
    }
  }
}
