.areas {
  background-color: $color-gris-01;
  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    @media screen and (max-width: 991px) {
      gap: 1rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    padding: 2rem;
    background-color: $color-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: $box-shadow;
    text-decoration: none;
    transition: $trans;
    @media screen and (max-width: 1419px) {
      padding: 2rem 1rem;
    }
    @media screen and (max-width: 991px) {
      padding: 1rem;
      height: 120px;
    }
    &:hover {
      background-color: $color-principal;
      transition: $trans;
      .areas__ico img {
        filter: grayscale(1) invert(1);
        transition: $trans;
      }
      .areas__nombre {
        color: $color-background;
        transition: $trans;
      }
    }
  }
  &__ico {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      @media screen and (max-width: 991px) {
        width: 70%;
      }
    }
  }
  &__nombre {
    font-size: 1rem;
    color: $color-secundario;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 5px;
    text-align: center;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      font-weight: 700;
    }
  }
}
