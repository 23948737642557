.control-hero {
  margin-top: 2rem;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  &__bg {
    background-size: cover;
    height: 300px;
    background-position: center center;
    border-radius: $border-radius;
    @media screen and (max-width: 767px) {
      border-radius: 0;
      height: 250px;
    }
  }
}

.control {
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: 5rem;
  @media screen and (max-width: 991px) {
    gap: 3rem;
  }
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
  }
  &__main {
  }
  &__content {
    
  }
  .pre-titulo:nth-child(2){
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  &__galeria {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1px;
  }
  &__galeria-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &__descripcion {
    padding-left: 6rem;
  }

  &__categorias {
  }

  &__titular {
    margin-bottom: 1.5rem !important;
  }

  &__content {
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
  &__titulo {
    a {
      font-size: 1.2rem;
      color: $color-principal;
      font-weight: 500;
      line-height: 1;
      &:hover {
        color: $color-alternativo;
      }
    }
  }
}
