
@mixin grid-servicios-realizados {
  grid-template-columns: .5fr 3.5fr 2fr 2fr;
}

.servicios-realizados {

		&__content {
		}

		&__item {
      display: grid;
      @include grid-servicios-realizados();
      gap: 3rem;
      border-top: solid rgba($color: #000000, $alpha: 0.2) 2px;
      padding: 2rem 0rem 2rem 0rem;
      @media screen and (max-width: 575px) {
        grid-template-columns: 1fr;
        gap: .2rem;
        padding: 1.4rem 0;
      }
		}
    &__titulo {
      display: grid;
      @include grid-servicios-realizados();
      gap: 3rem;
      border-top: solid rgba($color: #000000, $alpha: 0.2) 2px;
      padding: 2rem 0rem 2rem 0rem;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
    &__t-titular {
      font-size: 0.9rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-principal;
      letter-spacing: 1px;

    }

		&__proyecto {
      color: $color-principal;
      @media screen and (max-width: 575px) {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
        
      }    
		}

    &__cliente {
      font-weight: bold;
      @media screen and (max-width: 575px) {
        text-transform: uppercase;
        font-size: $font-size-small;
        margin-bottom: 0.2rem;
        font-weight: normal;
      }  
		}

		&__tipo {
      @media screen and (max-width: 575px) {
        color: $color-alternativo;
      }  
		}

		&__ano {
      @media screen and (max-width: 575px) {
        // color: $color-principal; 
        margin-bottom: 0.2rem;
      }
		}
}
