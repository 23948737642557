.header {
  background-color: $color-principal;
  &__container {
    display: flex;
  }
  &__logo {
    width: 165px;
    height: auto;
    @media screen and (max-width: 1199px) {
      width: 130px
    }
    @media screen and (max-width: 991px) {
      width: 130px;
    }
    @media screen and (max-width: 575px) {
      width: 95px;
    }
  }
  &__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 0rem;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }


  &__burger {
    margin-left: auto;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }


  &__center {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;

  }

}