body {
  background-color: white;
  font-size: 1rem;
  color: #6C6C6C;
  font-weight: 400;
  font-family: nimbus-sans, sans-serif; }

a {
  color: #AFBC16;
  text-decoration: none;
  transition: 0.1s all ease-in-out; }
  a:hover {
    color: #012F54;
    transition: 0.1s all ease-in-out; }

.pad-b-min {
  padding-bottom: 2rem; }

.mar-b-min {
  margin-bottom: 2rem; }

.pad-b {
  padding-bottom: 4rem; }

.mar-b {
  margin-bottom: 4rem; }

.pad-b-max {
  padding-bottom: 8rem; }
  @media screen and (max-width: 991px) {
    .pad-b-max {
      padding-bottom: 2rem; } }

.mar-b-max {
  margin-bottom: 8rem; }

.pad-t-min {
  padding-top: 2rem; }

.mar-t-min {
  margin-top: 2rem; }

.pad-t {
  padding-top: 4rem; }

.mar-t {
  margin-top: 4rem; }

.pad-t-max {
  padding-top: 8rem; }

.mar-t-max {
  margin-top: 8rem; }

.pad-min {
  padding: 2rem 0; }
  @media screen and (max-width: 575px) {
    .pad-min {
      padding: 0.8rem; } }

.pad {
  padding: 4rem 0; }
  @media screen and (max-width: 991px) {
    .pad {
      padding: 2rem 0; } }

.pad-max {
  padding: 8rem 0; }
  @media screen and (max-width: 991px) {
    .pad-max {
      padding: 4rem 0; } }

.mar-r {
  margin-right: 0.66667rem; }

.color-primario {
  background-color: #012F54; }

.color-secundario {
  background-color: #2C7DBE; }

.bg-primario {
  background-color: #012F54; }

.bg-primario {
  background-color: #2C7DBE; }

.bg-gris-light {
  background-color: #F4F4F4; }

/* SCSS Headers
--------------------------------------------------- */
.titulo {
  font-size: 2.0625rem;
  color: #012F54;
  font-weight: bold;
  margin-bottom: 1.5rem; }

.subtitulo {
  font-size: 1.3rem;
  color: #2C7DBE;
  font-weight: 500;
  margin-bottom: 1.5rem; }

.titulo--min {
  font-size: 1.5rem;
  margin-bottom: 1rem; }

.pre-titulo {
  font-size: 0.875rem;
  color: #2C7DBE;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 0.5rem; }

.pre-titulo--min {
  margin-bottom: 0.5rem; }

.header {
  background-color: #012F54; }
  .header__container {
    display: flex; }
  .header__logo {
    width: 165px;
    height: auto; }
    @media screen and (max-width: 1199px) {
      .header__logo {
        width: 130px; } }
    @media screen and (max-width: 991px) {
      .header__logo {
        width: 130px; } }
    @media screen and (max-width: 575px) {
      .header__logo {
        width: 95px; } }
  .header__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 0rem; }
    @media screen and (max-width: 991px) {
      .header__menu {
        display: none; } }
  .header__burger {
    margin-left: auto; }
    @media screen and (min-width: 992px) {
      .header__burger {
        display: none; } }
  .header__center {
    display: flex;
    align-items: center; }
  .header__right {
    display: flex;
    align-items: center; }

.widget-area {
  background-color: #012F54; }
  .widget-area a:hover {
    color: #2C7DBE; }
  .widget-area__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    border-bottom: 3px dotted rgba(255, 255, 255, 0.3);
    margin-bottom: 2rem;
    padding-bottom: 2rem; }
    @media screen and (max-width: 575px) {
      .widget-area__content {
        display: flex;
        flex-direction: column;
        gap: 0rem; } }
  .widget-area__item {
    color: white;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .widget-area__item {
        margin-bottom: 1.5rem; } }

.logos-iso__line {
  display: flex;
  justify-content: center !important;
  align-items: flex-start;
  margin-bottom: 1rem; }

.logos-iso__v {
  width: 40px;
  height: auto;
  margin-right: 5px;
  border-radius: 5px;
  padding: .2rem;
  background-color: #fff; }

.logos-iso__h {
  width: 65px;
  height: auto;
  margin-right: 5px;
  border-radius: 5px;
  padding: .2rem;
  background-color: #fff; }

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .footer {
      flex-direction: column; } }
  .footer__copy {
    flex-direction: column;
    color: white; }
    @media screen and (max-width: 767px) {
      .footer__copy {
        order: 1;
        text-align: center; } }
    .footer__copy p {
      display: inline-block;
      margin-right: 0.7rem;
      display: block;
      width: 100%; }
    .footer__copy a:hover {
      color: #2C7DBE; }
  .footer__asociados {
    margin-right: 1rem; }
    @media screen and (max-width: 991px) {
      .footer__asociados {
        margin-bottom: 3rem;
        margin-right: 0; } }
    @media screen and (max-width: 575px) {
      .footer__asociados {
        display: flex;
        align-items: center; } }
    .footer__asociados img {
      width: auto;
      height: 40px;
      margin-left: 2rem;
      opacity: .5; }
      @media screen and (max-width: 991px) {
        .footer__asociados img {
          margin-left: 0rem;
          margin-right: 1.5rem; } }
      @media screen and (max-width: 575px) {
        .footer__asociados img {
          margin-left: .5rem;
          margin-right: .5rem;
          height: 30px; } }
      .footer__asociados img:nth-child(2) {
        width: auto;
        height: 50px; }

.nav-header__nav {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 1rem; }

.nav-header .menu-item a {
  padding: 0.7rem 1.0rem;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: 0.1s all ease-in-out; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-header .menu-item a {
      padding: 0.7rem .7rem; } }
  .nav-header .menu-item a:hover {
    color: #AFBC16;
    transition: 0.1s all ease-in-out; }

.btn-overlay {
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .btn-overlay img {
    height: 25px;
    width: auto; }

.m-overlay {
  background-color: #012F54;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 30; }
  .m-overlay a:hover {
    color: white; }
  .m-overlay__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; }
  .m-overlay__close {
    padding: 3rem 3rem 0rem 0rem;
    display: flex;
    justify-content: flex-end; }
    .m-overlay__close img {
      height: 20px;
      width: auto; }
  .m-overlay__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    flex-direction: column; }
  .m-overlay__ul {
    list-style: none;
    font-size: 3.5rem;
    text-align: center;
    font-weight: bold;
    margin: 0px;
    padding: 0px; }
    @media screen and (max-width: 575px) {
      .m-overlay__ul {
        font-size: 2rem; } }
  .m-overlay__contacto {
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold; }
    @media screen and (max-width: 575px) {
      .m-overlay__contacto {
        font-size: 2rem; } }

.page-title {
  background-color: #012F54;
  padding: 3.2rem 0; }
  @media screen and (max-width: 991px) {
    .page-title {
      padding: 0 0 1.25rem 0; } }
  @media screen and (max-width: 575px) {
    .page-title {
      padding: 1rem 0 1.2rem 0; } }
  .page-title__h {
    color: white;
    font-size: 4rem;
    font-weight: bold; }
    @media screen and (max-width: 991px) {
      .page-title__h {
        font-size: 3.2rem;
        margin-bottom: 0; } }
    @media screen and (max-width: 575px) {
      .page-title__h {
        font-size: 1.5rem;
        margin-bottom: 3px; } }
  .page-title__breadcrumbs {
    color: white; }
    @media screen and (max-width: 575px) {
      .page-title__breadcrumbs {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.534); } }
    .page-title__breadcrumbs a:hover {
      color: #C3DDF2; }

/* SCSS Botones
--------------------------------------------------- */
.boton {
  padding: 0.8rem 2rem 0.8em 2rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  transition: 0.1s all ease-in-out; }
  .boton:hover {
    transition: 0.1s all ease-in-out; }

.boton--principal {
  background-color: #012F54;
  color: white; }
  .boton--principal:hover {
    color: white;
    background-color: #2C7DBE; }

.boton--secundario {
  background-color: #C3DDF2;
  color: #012F54; }
  .boton--secundario:hover {
    color: white;
    background-color: #2C7DBE; }

.boton--alternativo {
  background-color: #AFBC16;
  color: white; }
  .boton--alternativo:hover {
    color: #AFBC16;
    background-color: white; }

.widget {
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .widget {
      margin-bottom: 1rem; } }
  .widget__title {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 575px) {
      .widget__title {
        margin-bottom: .7rem; } }

.areas {
  background-color: #F4F4F4; }
  .areas__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem; }
    @media screen and (max-width: 991px) {
      .areas__content {
        gap: 1rem; } }
    @media screen and (max-width: 575px) {
      .areas__content {
        grid-template-columns: repeat(2, 1fr); } }
  .areas__item {
    padding: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: 0.1s all ease-in-out; }
    @media screen and (max-width: 1419px) {
      .areas__item {
        padding: 2rem 1rem; } }
    @media screen and (max-width: 991px) {
      .areas__item {
        padding: 1rem;
        height: 120px; } }
    .areas__item:hover {
      background-color: #012F54;
      transition: 0.1s all ease-in-out; }
      .areas__item:hover .areas__ico img {
        filter: grayscale(1) invert(1);
        transition: 0.1s all ease-in-out; }
      .areas__item:hover .areas__nombre {
        color: white;
        transition: 0.1s all ease-in-out; }
  .areas__ico {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center; }
    .areas__ico img {
      width: 100%;
      height: auto; }
      @media screen and (max-width: 991px) {
        .areas__ico img {
          width: 70%; } }
  .areas__nombre {
    font-size: 1rem;
    color: #2C7DBE;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 5px;
    text-align: center; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .areas__nombre {
        font-weight: 700; } }

.cta {
  background-color: #C3DDF2; }
  @media screen and (max-width: 575px) {
    .cta {
      padding: 3rem 0; } }
  .cta__content {
    display: grid;
    grid-template-columns: 8fr 3fr;
    gap: 3rem; }
    @media screen and (max-width: 991px) {
      .cta__content {
        grid-template-columns: 1fr;
        gap: 2rem; } }
  @media screen and (max-width: 991px) {
    .cta__info {
      text-align: center; } }
  .cta__titulo {
    font-size: 3rem;
    color: #012F54;
    font-weight: bold;
    width: 70%; }
    @media screen and (max-width: 991px) {
      .cta__titulo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; } }
    @media screen and (max-width: 575px) {
      .cta__titulo {
        font-size: 2rem;
        width: 100%; } }
  .cta__subtitulo {
    font-size: 1.3125rem;
    color: #2C7DBE; }
    @media screen and (max-width: 991px) {
      .cta__subtitulo {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 575px) {
      .cta__subtitulo {
        font-size: 1rem;
        margin-bottom: 0rem;
        color: #212121; } }
  .cta__action {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 991px) {
      .cta__action {
        align-items: center; } }

.page .entry {
  margin-bottom: 3rem; }
  .page .entry h2 {
    font-size: 2.0625rem;
    color: #012F54;
    font-weight: bold;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 991px) {
      .page .entry h2 {
        margin-bottom: .7rem; } }
  .page .entry h3 {
    font-size: 1.6rem;
    color: #2C7DBE;
    font-weight: 500;
    margin-bottom: 1.5rem;
    width: 70%;
    margin-top: 2rem; }
    @media screen and (max-width: 767px) {
      .page .entry h3 {
        font-size: 1.4rem; } }
  .page .entry p {
    padding-left: 4rem; }
    @media screen and (max-width: 991px) {
      .page .entry p {
        padding-left: 0rem; } }
  .page .entry ul {
    list-style: none;
    padding-left: 4rem; }
    @media screen and (max-width: 991px) {
      .page .entry ul {
        padding-left: 2rem; } }
    @media screen and (max-width: 767px) {
      .page .entry ul {
        padding-left: 1rem; } }
  .page .entry li {
    padding-left: 1.8rem;
    position: relative;
    margin-bottom: 8px; }
    .page .entry li:before {
      left: 0;
      position: absolute;
      font-family: "FontAwesome";
      content: "\f105";
      font-weight: bold;
      color: #AFBC16;
      padding-right: 15px; }
      @media screen and (max-width: 767px) {
        .page .entry li:before {
          padding-right: 8px; } }

.post__entry p, .post__entry ul, .post__entry ol {
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
  line-height: 1.7;
  color: #484848; }

.f-hero {
  position: relative;
  background-color: #012F54; }
  .f-hero__overlay {
    right: 0;
    left: calc(50% + 1.5rem);
    height: 100%;
    position: absolute;
    overflow: hidden; }
  .f-hero__img {
    width: 100%;
    height: 510px;
    object-fit: cover; }
    @media screen and (max-width: 991px) {
      .f-hero__img {
        display: none;
        height: 100%; } }

.hero {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 991px) {
    .hero {
      display: flex; } }
  .hero__content {
    padding: 7rem 0; }
    @media screen and (max-width: 991px) {
      .hero__content {
        padding: 4rem 0; } }
  .hero__pretitulo {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #C3DDF2;
    font-weight: bold; }
  .hero__titulo {
    font-size: 2.3rem;
    font-weight: bold;
    color: white;
    margin-bottom: 2rem;
    width: 90%; }
    @media screen and (max-width: 1199px) {
      .hero__titulo {
        width: 100%; } }
    @media screen and (max-width: 575px) {
      .hero__titulo {
        width: 100%;
        font-size: 2.4375rem; } }
  .hero__imagen {
    position: relative; }

.logos__content {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 575px) {
    .logos__content {
      display: grid;
      padding: 0.5rem;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem 1rem; } }

@media screen and (max-width: 575px) {
  .logos__item {
    display: flex;
    justify-content: center; } }

.logos__item img {
  max-height: 55px;
  max-width: 100px; }
  @media screen and (max-width: 991px) {
    .logos__item img {
      max-height: 35px; } }
  @media screen and (max-width: 575px) {
    .logos__item img {
      max-height: 25px; } }

.servicios__item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  margin-bottom: 4rem; }
  @media screen and (max-width: 991px) {
    .servicios__item {
      margin-bottom: 3rem; } }
  @media screen and (max-width: 991px) {
    .servicios__item {
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  .servicios__item:nth-child(2) .servicios__img {
    grid-column: 6 / 13;
    grid-row: 1; }
  .servicios__item:nth-child(2) .servicios__contenido {
    grid-column: 2 / 7;
    grid-row: 1; }

.servicios__img {
  grid-column: 1 / 8;
  grid-row: 1; }
  .servicios__img img {
    width: 100%;
    max-height: 420px;
    object-fit: cover; }

.servicios__contenido {
  grid-column: 7 / 12;
  grid-row: 1;
  display: flex;
  align-items: flex-end; }

.servicios__content {
  padding: 3rem;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  transition: 0.1s all ease-in-out; }
  .servicios__content:hover {
    transition: 0.1s all ease-in-out;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12); }
    .servicios__content:hover h2 {
      color: #AFBC16;
      transition: 0.1s all ease-in-out; }
    .servicios__content:hover .servicios__link {
      color: #AFBC16 !important;
      transition: 0.1s all ease-in-out; }
  @media screen and (max-width: 991px) {
    .servicios__content {
      padding: 1.5rem;
      margin: 0rem 1rem;
      margin-top: -2rem; } }
  .servicios__content .titulo {
    transition: 0.1s all ease-in-out;
    margin-bottom: 1rem;
    color: #012F54; }

.servicios__descripcion p {
  color: #484848; }

.servicios__link {
  color: #AFBC16; }
  .servicios__link:hover {
    color: #AFBC16; }

.servicios-extra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .servicios-extra {
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  .servicios-extra__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
    @media screen and (max-width: 991px) {
      .servicios-extra__item {
        margin-bottom: 3rem; } }
  .servicios-extra__picture {
    width: 100%;
    height: 350px; }
    .servicios-extra__picture img {
      width: 100%;
      height: 315px;
      object-fit: cover; }
  .servicios-extra__pic {
    width: 100%;
    height: 350px;
    object-fit: cover; }
  .servicios-extra__content {
    background-color: white;
    padding: 1.5rem;
    width: 80%;
    position: relative;
    z-index: 2;
    margin-top: -8rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.1s all ease-in-out; }
    .servicios-extra__content:hover {
      transition: 0.1s all ease-in-out;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12); }
      .servicios-extra__content:hover h2 {
        color: #AFBC16;
        transition: 0.1s all ease-in-out; }
      .servicios-extra__content:hover .servicios__link {
        color: #AFBC16 !important;
        transition: 0.1s all ease-in-out; }

.addaops {
  background: url("../assets/site/empresa@2x.jpg") center center;
  background-size: cover;
  height: 600px; }
  @media screen and (max-width: 991px) {
    .addaops {
      height: 400px; } }
  .addaops__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%; }
    @media screen and (max-width: 991px) {
      .addaops__container {
        align-items: center; } }
    @media screen and (max-width: 575px) {
      .addaops__container {
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.7);
        justify-content: center;
        align-items: center; } }
  .addaops__content {
    background-color: white;
    width: 45%;
    padding: 3rem;
    transition: 0.1s all ease-in-out; }
    .addaops__content:hover h2 {
      color: #AFBC16; }
    @media screen and (max-width: 991px) {
      .addaops__content {
        width: 100%;
        padding: 2rem; } }
    @media screen and (max-width: 575px) {
      .addaops__content {
        width: 100%;
        padding: 2rem;
        background-color: transparent;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center; } }
    @media screen and (max-width: 575px) {
      .addaops__content h2, .addaops__content h3 {
        color: white; } }
    @media screen and (max-width: 575px) {
      .addaops__content h2 {
        margin-bottom: 0.7rem; } }
  .addaops__descripcion p {
    color: #484848; }
    @media screen and (max-width: 575px) {
      .addaops__descripcion p {
        color: white; } }

.contacto {
  display: grid;
  grid-template-columns: 5fr 6fr;
  gap: 5rem; }
  @media screen and (max-width: 991px) {
    .contacto {
      display: flex;
      flex-direction: column; } }
  .contacto hr {
    margin: 2.5rem 0; }
  .contacto__titulo {
    font-size: 1.2em;
    font-weight: bold;
    color: #012F54;
    margin-bottom: 2rem; }
    @media screen and (max-width: 991px) {
      .contacto__titulo {
        margin-bottom: 1rem; } }
  .contacto__subtitulo {
    font-size: 1.6rem;
    font-weight: bold;
    color: #012F54;
    margin-bottom: 1rem; }
  .contacto__oficinas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem 2rem; }
    @media screen and (max-width: 991px) {
      .contacto__oficinas {
        margin-bottom: 1.5rem; } }
  .contacto address {
    margin-bottom: 0px; }
    .contacto address span {
      display: inline-block; }
    .contacto address small {
      color: #aaaaaa; }
  .contacto .doble {
    display: flex; }
  .contacto .telefono {
    margin-left: 10px;
    width: 48.5%; }

.form-control {
  border-bottom: solid 1px #6C6C6C !important;
  border-right: none;
  border-top: none;
  border-left: none;
  border-radius: 0px; }

.wpcf7-form-control {
  margin-bottom: 1.5rem; }
  .wpcf7-form-control::placeholder {
    font-size: 14px;
    color: #aaaaaa; }

.wpcf7-textarea {
  height: 150px; }

.wpcf7-submit {
  border: none; }

.wpcf7-acceptance {
  font-size: 0.7rem;
  color: #aaaaaa; }
  .wpcf7-acceptance a {
    font-size: 0.7rem; }

.wpcf7-list-item {
  margin-left: 0 !important;
  margin-bottom: 1rem !important; }

.lideres {
  display: grid;
  grid-template-columns: 4fr 2fr 6fr;
  gap: 1rem; }
  @media screen and (max-width: 991px) {
    .lideres {
      display: flex;
      flex-direction: column; } }
  .lideres .titulo {
    text-align: right; }
    @media screen and (max-width: 991px) {
      .lideres .titulo {
        text-align: left;
        order: 2;
        margin-bottom: 2rem; } }
  .lideres__icono {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .lideres__icono img {
      max-width: 105px;
      height: auto; }
    @media screen and (max-width: 991px) {
      .lideres__icono {
        align-items: flex-start; } }
    @media screen and (max-width: 991px) {
      .lideres__icono {
        order: 1;
        margin-bottom: .7rem;
        justify-content: flex-start; } }
    @media screen and (max-width: 991px) {
      .lideres__icono img {
        width: 80px; } }
  .lideres__descripcion .entry p {
    padding-left: 0; }
  @media screen and (max-width: 991px) {
    .lideres__descripcion {
      order: 3; } }

.control-hero {
  margin-top: 2rem; }
  @media screen and (max-width: 767px) {
    .control-hero {
      margin-top: 0; } }
  .control-hero__bg {
    background-size: cover;
    height: 300px;
    background-position: center center;
    border-radius: 0.5rem; }
    @media screen and (max-width: 767px) {
      .control-hero__bg {
        border-radius: 0;
        height: 250px; } }

.control {
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: 5rem; }
  @media screen and (max-width: 991px) {
    .control {
      gap: 3rem; } }
  @media screen and (max-width: 575px) {
    .control {
      display: flex;
      flex-direction: column; } }
  .control .pre-titulo:nth-child(2) {
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .control__galeria {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px; }
  .control__galeria-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .control__descripcion {
    padding-left: 6rem; }
  .control__titular {
    margin-bottom: 1.5rem !important; }
  .control__item {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 1rem;
    margin-bottom: 1.5rem; }
    .control__item img {
      width: 100%;
      height: auto;
      border-radius: 5px; }
  .control__titulo a {
    font-size: 1.2rem;
    color: #012F54;
    font-weight: 500;
    line-height: 1; }
    .control__titulo a:hover {
      color: #AFBC16; }

.empresa-hero {
  height: 350px;
  background: url("../assets/site/empresa@2x.jpg") no-repeat;
  background-size: cover;
  background-position: center center; }
  @media screen and (max-width: 575px) {
    .empresa-hero {
      height: 250px; } }

.empresa__item {
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 4rem;
  margin-bottom: 3.7rem; }
  @media screen and (max-width: 991px) {
    .empresa__item {
      display: flex;
      flex-direction: column; } }

.empresa__titulo {
  text-align: right; }
  @media screen and (max-width: 991px) {
    .empresa__titulo {
      text-align: left; } }

.compromiso-hero {
  height: 350px;
  background: url("../assets/site/empresa-hero@2x.jpg") no-repeat;
  background-size: cover;
  background-position: center center; }
  @media screen and (max-width: 991px) {
    .compromiso-hero {
      display: none; } }

.compromiso__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.compromiso__item {
  display: flex;
  margin-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .compromiso__item {
      display: block; } }
  .compromiso__item:nth-child(2) {
    flex-direction: row-reverse; }
    .compromiso__item:nth-child(2) .compromiso__figure {
      margin-left: 3rem;
      margin-right: 0; }
      @media screen and (max-width: 991px) {
        .compromiso__item:nth-child(2) .compromiso__figure {
          margin-left: 0; } }

.compromiso__figure {
  flex: 1 1 60%; }
  .compromiso__figure img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 0.5rem; }
    @media screen and (max-width: 991px) {
      .compromiso__figure img {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 2rem;
        height: 250px; } }
    @media screen and (max-width: 767px) {
      .compromiso__figure img {
        height: 150px; } }

.compromiso__descripcion {
  flex: 1 1 50%;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .compromiso__descripcion {
      margin-left: 0; } }

.grid-noticias {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem 3rem; }
  @media screen and (min-width: 1419px) {
    .grid-noticias {
      grid-template-columns: repeat(4, 1fr); } }
  @media screen and (max-width: 991px) {
    .grid-noticias {
      grid-template-columns: 1fr 1fr;
      gap: 3rem 2rem; } }
  @media screen and (max-width: 575px) {
    .grid-noticias {
      grid-template-columns: 1fr;
      gap: 2rem 1rem; } }
  .grid-noticias__item {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 575px) {
      .grid-noticias__item {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 1rem 1rem;
        background: none;
        border: none;
        box-shadow: none;
        border-radius: none;
        overflow: initial; } }
  .grid-noticias__content {
    padding: 1.5rem; }
    @media screen and (max-width: 575px) {
      .grid-noticias__content {
        padding: 0rem; } }
  .grid-noticias__imagen {
    margin-bottom: 1rem; }
    .grid-noticias__imagen img {
      width: 100%;
      height: auto; }
  .grid-noticias__titulo {
    font-size: 1.5rem;
    font-weight: bold; }
    @media screen and (max-width: 575px) {
      .grid-noticias__titulo {
        font-size: 1.2rem; } }
    .grid-noticias__titulo a {
      color: #012F54; }
  .grid-noticias__fecha {
    font-size: 0.8rem;
    color: #6C6C6C;
    font-weight: 400; }
  .grid-noticias__tax {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px; }

.grid-noticia {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 6rem; }
  @media screen and (max-width: 991px) {
    .grid-noticia {
      gap: 3rem; } }
  @media screen and (max-width: 575px) {
    .grid-noticia {
      display: flex;
      flex-direction: column; } }
  .grid-noticia__main {
    font-size: 1.2rem;
    line-height: 1.5; }
    .grid-noticia__main img {
      width: 100%;
      height: auto; }
  .grid-noticia__titulo {
    font-size: 3rem;
    font-weight: 700;
    color: #012F54; }
  .grid-noticia__detalles {
    display: flex;
    margin-bottom: 2rem; }
  .grid-noticia__fecha {
    font-size: 0.8rem;
    color: #6C6C6C;
    font-weight: 400; }
  .grid-noticia__tax {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px; }
    .grid-noticia__tax span {
      margin-right: 0.3rem; }
  @media screen and (max-width: 575px) {
    .grid-noticia__sidebar {
      display: flex; } }
  @media screen and (max-width: 575px) {
    .grid-noticia__sidebar .widget {
      margin-right: 3rem; } }
  .grid-noticia__sidebar .widget__title {
    color: #012F54;
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem; }
  .grid-noticia__sidebar ul {
    list-style: none;
    padding-left: 0px; }

.servicios-realizados__item {
  display: grid;
  grid-template-columns: .5fr 3.5fr 2fr 2fr;
  gap: 3rem;
  border-top: solid rgba(0, 0, 0, 0.2) 2px;
  padding: 2rem 0rem 2rem 0rem; }
  @media screen and (max-width: 575px) {
    .servicios-realizados__item {
      grid-template-columns: 1fr;
      gap: .2rem;
      padding: 1.4rem 0; } }

.servicios-realizados__titulo {
  display: grid;
  grid-template-columns: .5fr 3.5fr 2fr 2fr;
  gap: 3rem;
  border-top: solid rgba(0, 0, 0, 0.2) 2px;
  padding: 2rem 0rem 2rem 0rem; }
  @media screen and (max-width: 575px) {
    .servicios-realizados__titulo {
      display: none; } }

.servicios-realizados__t-titular {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #012F54;
  letter-spacing: 1px; }

.servicios-realizados__proyecto {
  color: #012F54; }
  @media screen and (max-width: 575px) {
    .servicios-realizados__proyecto {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 0.2rem; } }

.servicios-realizados__cliente {
  font-weight: bold; }
  @media screen and (max-width: 575px) {
    .servicios-realizados__cliente {
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-bottom: 0.2rem;
      font-weight: normal; } }

@media screen and (max-width: 575px) {
  .servicios-realizados__tipo {
    color: #AFBC16; } }

@media screen and (max-width: 575px) {
  .servicios-realizados__ano {
    margin-bottom: 0.2rem; } }
