.compromiso-hero {
  height: 350px;
  background: url("../assets/site/empresa-hero@2x.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.compromiso {
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    display: flex;
    margin-bottom: 5rem;
    @media screen and (max-width: 991px) {
      display: block;
    }



    &:nth-child(2) {
      flex-direction: row-reverse;
      // @media screen and (max-width: 991px) {
      //   flex-direction: column;
      // }

      .compromiso__figure {
        margin-left: 3rem;
        margin-right: 0;
        @media screen and (max-width: 991px) {
          margin-left: 0;
        }
      }

    }
    // &:nth-child(3) {
    //   @media screen and (max-width: 991px) {
    //     margin-bottom: 0px;
    //   }
    // }


  }

  &__figure {
    flex: 1 1 60%;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-radius: $border-radius;
      @media screen and (max-width: 991px) {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 2rem;
        height: 250px;
      }
      @media screen and (max-width: 767px) {
        height: 150px;
      }
    }
  
  
  }

  &__descripcion {
    flex: 1 1 50%;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }


  &__texto {

  }



  &__titulo {
  }


}
// .img-r {
//   width: 500px;
//   height: auto;
//   margin-left: 5rem;
//   margin-right: 0px !important;
//   @media screen and (max-width: 991px) {
//     margin-left: 0rem;
//   }
// }
