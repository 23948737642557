/* SCSS Botones
--------------------------------------------------- */
.boton {
  padding: 0.8rem 2rem 0.8em 2rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  transition: $trans;
  &:hover {
    transition: $trans;
  }
}

.boton--principal {
  background-color: $color-principal;
  color: $color-background;

  &:hover {
    color: $color-background;
    background-color: $color-secundario;
  }
}

.boton--secundario {
  background-color: $color-terciario;
  color: $color-principal;
  &:hover {
    color: $color-background;
    background-color: $color-secundario;
  }
}

.boton--alternativo {
  background-color: $color-alternativo;
  color: $color-background;
  &:hover {
    color: $color-alternativo;
    background-color: $color-background;
  }
}
