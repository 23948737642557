.widget {
  margin-bottom: 3rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 1rem;
  }
  &__title {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 575px) {
      margin-bottom: .7rem;
    }
  }
}