
.grid-noticias {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 5rem 3rem;

  @media screen and (min-width: 1419px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    gap: 3rem 2rem;
  }

  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 2rem 1rem;
  }

  &__item {
    background-color: $color-background;
    border-radius: 10px;
    box-shadow: $box-shadow;
    overflow: hidden;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 1rem 1rem;
      background: none;
      border: none;
      box-shadow: none;
      border-radius: none;
      overflow: initial;
    }
  }

  &__content {
    padding: 1.5rem;
    @media screen and (max-width: 575px) {
      padding: 0rem;
    }
  }


  &__imagen {
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__titulo {
    font-size: 1.5rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      font-size: 1.2rem;
    }
    a {
      color: $color-principal;
    }
  }
  &__fecha {
    font-size: $font-size-small;
    color: $color-gris-02;
    font-weight: 400;
  }
  &__tax {
    text-transform: uppercase;
    font-size: $font-size-small;
    letter-spacing: 1px;
  }
}


.grid-noticia {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 6rem;
  @media screen and (max-width: 991px) {
    gap: 3rem;
  }
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
  }
  &__main {
    font-size: 1.2rem;
    line-height: 1.5;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__titulo {
    font-size: 3rem;
    font-weight: 700;
    color: $color-principal;
  }
  &__detalles {
    display: flex;
    margin-bottom: 2rem;
  }
  &__fecha {
    font-size: $font-size-small;
    color: $color-gris-02;
    font-weight: 400;
  }
  &__tax {
    text-transform: uppercase;
    font-size: $font-size-small;
    letter-spacing: 1px;
    span {
      margin-right: 0.3rem;
    }
  }
  &__entry {

  }

  &__sidebar {
    @media screen and (max-width: 575px) {
      display: flex;
    }
    .widget {
      @media screen and (max-width: 575px) {
        margin-right: 3rem;
      }
    }
    .widget__title {
      color: $color-principal;
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }
    ul {
      list-style: none;
      padding-left: 0px;
    }
  }
}