.nav-header {
  &__nav {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 1rem;
  }
  .menu-item {
    a {
      padding: 0.7rem 1.0rem;
      text-decoration: none;
      color: $color-background;
      text-transform: uppercase;
      font-size: $font-size-small;
      letter-spacing: 1px;
      transition: $trans;
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: 0.7rem .7rem;
      }
      &:hover {
        color: $color-alternativo;
        transition: $trans;
      }
    }
  }
}

.btn-overlay {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  img {
    height: 25px;
    width: auto;
  }


}

.m-overlay {
  background-color: $color-principal;
  position: fixed;  
  top: 0;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 30;
  a {
    &:hover {
      color: $color-background;
    }
  }
  &__container {

  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__close {
    padding: 3rem 3rem 0rem 0rem;
    display: flex;
    justify-content: flex-end;
    img {
      height: 20px;
      width: auto;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    flex-direction: column;
  }
  &__menu {

  }
  &__ul {
    list-style: none;
    font-size: 3.5rem;
    text-align: center;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 575px) {
      font-size: 2rem;
    }
  }
  &__contacto {
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      font-size: 2rem;
    }
  }
}