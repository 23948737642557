.lideres {
  display: grid;
  grid-template-columns: 4fr 2fr 6fr;
  gap: 1rem;
  @media screen and (max-width: 991px) {
    
  }
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
  & .titulo {
    text-align: right;
    @media screen and (max-width: 991px) {
      text-align: left;
      order: 2;
      margin-bottom: 2rem;
    }
  }
  &__icono {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      max-width: 105px;
      height: auto;
    }
    @media screen and (max-width: 991px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 991px) {
      order: 1;
      margin-bottom: .7rem;
      justify-content: flex-start;
    }
    img {
      @media screen and (max-width: 991px) {
        width: 80px;
      }
    }
  }
  &__descripcion {
    .entry {
      p {
        padding-left: 0;
      }
    }
    @media screen and (max-width: 991px) {
      order: 3;
    }
  }
}



