body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-gris-02;
  font-weight: $font-weight;
  font-family: $typo-principal;
}

a {
  color: $color-alternativo;
  text-decoration: none;
  transition: $trans;
  &:hover {
    color: $color-principal;
    transition: $trans;
  }
}
