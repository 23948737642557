$color-principal: #012F54;
$color-secundario: #2C7DBE;
$color-terciario: #C3DDF2;
$color-alternativo: #AFBC16;

$color-gris-01: #F4F4F4;
$color-gris-02: #6C6C6C;
$color-gris-03: #484848;
$color-gris-04: #212121;

$color-background: rgba(255, 255, 255, 1);

$font-size-big: 1.2rem;
$font-size: 1rem;
$font-size-small: 0.8rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 0 30px 0 rgba(0, 0, 0, 0.12);

$border-radius: $font-size / 2;

$trans: 0.1s all ease-in-out;

$sp-1: 2rem;
$sp-2: 4rem;
$sp-3: 8rem;

$typo-principal: nimbus-sans, sans-serif;
$typo-secundaria: "Georgia", serif;