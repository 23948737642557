.empresa-hero {
  height: 350px;
  background: url("../assets/site/empresa@2x.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  @media screen and (max-width: 575px) {
    height: 250px;
  }
}

.empresa {
  &__content {
  }

  &__item {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 4rem;
    margin-bottom: 3.7rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__titulo {
    text-align: right;
    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  &__descripcion {
  }
}
