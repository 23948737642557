.contacto {
  display: grid;
  grid-template-columns: 5fr 6fr;
  gap: 5rem;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
  hr {
    margin: 2.5rem 0;
  }
  &__content {
  }

  &__titulo {
    font-size: 1.2em;
    font-weight: bold;
    color: $color-principal;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  &__subtitulo {
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-principal;
    margin-bottom: 1rem;

  }
  &__oficinas {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2.5rem 2rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem;
    }
  }
  address {
    margin-bottom: 0px;
    span {
      display: inline-block;
    }
    small {
      color: rgb(170, 170, 170);
    }
  }
  .doble {
    display: flex;
  }
  .telefono {
    margin-left: 10px;
    width: 48.5%;
  }
}

.form-control {
  border-bottom: solid 1px $color-gris-02 !important;
  border-right: none;
  border-top: none;
  border-left: none;
  border-radius: 0px;
}

.wpcf7-form-control {
  margin-bottom: 1.5rem;
  &::placeholder {
    font-size: 14px;
    color: rgb(170, 170, 170);
  }
}

.wpcf7-textarea {
  height: 150px;
}

.wpcf7-submit {
  border: none;
}

.wpcf7-acceptance {
  font-size: $font-size-smaller;
  color: rgb(170, 170, 170);
  a {
    font-size: $font-size-smaller;
  }
}

.wpcf7-list-item {
  margin-left: 0 !important;
  margin-bottom: 1rem !important;
}


