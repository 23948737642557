/* SCSS Headers
--------------------------------------------------- */
.titulo {
  font-size: 2.0625rem;
  color: $color-principal;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.subtitulo {
  font-size: 1.3rem;
  color: $color-secundario;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.titulo--min {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.pre-titulo {
  font-size: 0.875rem;
  color: $color-secundario;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.pre-titulo--min {
  margin-bottom: 0.5rem;
}

.h1-header {
}
.h2-header {
}
.h3-header {
}
.h4-header {
}
.h5-header {
}
