.f-hero {
  position: relative;
  background-color: $color-principal;

  &__overlay {
    right: 0;
    left: calc(50% + 1.5rem);
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 510px;
    object-fit: cover;
    @media screen and (max-width: 991px) {
      display: none;
      height: 100%;
    }
  }
}

.hero {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 991px) {
    display: flex;
  }
  &__content {
    padding: 7rem 0;
    @media screen and (max-width: 991px) {
      padding: 4rem 0;
    }
  }
  &__pretitulo {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-terciario;
    font-weight: bold;
  }
  &__titulo {
    font-size: 2.3rem;
    font-weight: bold;
    color: $color-background;
    margin-bottom: 2rem;
    width: 90%;
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      font-size: 2.4375rem;
    }
  }
  &__imagen {
    position: relative;
  }
}

.logos {
  &__container {
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 575px) {
      display: grid;
      padding: 0.5rem;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem 1rem;
    }
  }
  &__item {
    @media screen and (max-width: 575px) {
      display: flex;
      justify-content:center;
    }  
    img {
      max-height: 55px;
      max-width: 100px;
      @media screen and (max-width: 991px) {
        max-height: 35px;
      }
      @media screen and (max-width: 575px) {
        max-height: 25px;
      }
    }
  }
}

.servicios {
  &__item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    margin-bottom: 4rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &:nth-child(2) {
      .servicios__img {
        grid-column: 6 / 13;
        grid-row: 1;
      }
      .servicios__contenido {
        grid-column: 2 / 7;
        grid-row: 1;
      }
    }
  }

  &__img {
    grid-column: 1 / 8;
    grid-row: 1;

    img {
      width: 100%;
      max-height: 420px;
      object-fit: cover;
    }
  }
  &__contenido {
    grid-column: 7 / 12;
    grid-row: 1;
    display: flex;
    align-items: flex-end;

  }
  &__content {
    padding: 3rem;
    background-color: $color-background;
    box-shadow: $box-shadow;
    transition: $trans;
    &:hover {
      transition: $trans;
      box-shadow: $box-shadow-hover;
      h2 {
        color: $color-alternativo;
        transition: $trans;
      }
      .servicios__link {
        color: $color-alternativo!important;
        transition: $trans;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 1.5rem;
      margin: 0rem 1rem;
      margin-top: -2rem;
    }
    .titulo {
      transition: $trans;
      margin-bottom: 1rem;
      color: $color-principal;
    }
  }
  &__descripcion {
    p {
      color: $color-gris-03;

    }
  }
  &__link {
    color: $color-alternativo;
    &:hover {
      color: $color-alternativo;
    }
  }
}

.servicios-extra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @media screen and (max-width: 991px) {
      margin-bottom: 3rem;
    }
  }

  &__picture {
    width: 100%;
    height: 350px;
    img {
      width: 100%;
      height: 315px;
      object-fit: cover;
    }
  }

  &__pic {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  &__content {
    background-color: $color-background;
    padding: 1.5rem;
    width: 80%;
    position: relative;
    z-index: 2;
    margin-top: -8rem;
    box-shadow: $box-shadow;
    transition: $trans;
    &:hover {
      transition: $trans;
      box-shadow: $box-shadow-hover;
      h2 {
        color: $color-alternativo;
        transition: $trans;
      }
      .servicios__link {
        color: $color-alternativo!important;
        transition: $trans;
      }
    }
  }
}

.addaops {
  background: url("../assets/site/empresa@2x.jpg") center center;
  background-size: cover;
  height: 600px;
  @media screen and (max-width: 991px) {
    height: 400px;
  }
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    @media screen and (max-width: 991px) {
      align-items: center;
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      background-color: rgba($color: #000000, $alpha: 0.7);
      justify-content: center;
      align-items: center;
    }
  }
  &__content {
    background-color: $color-background;
    width: 45%;
    padding: 3rem;
    transition: $trans;
    &:hover {
      h2 {
        color: $color-alternativo;
      }
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 2rem;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      padding: 2rem;
      background-color: transparent;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    h2,h3 {
      @media screen and (max-width: 575px) {
        color: $color-background;
      }
    }
    h2 {
      @media screen and (max-width: 575px) {
        margin-bottom: 0.7rem;
      }
    }
   
  }

  &__descripcion {
    p {
      color: $color-gris-03;
      @media screen and (max-width: 575px) {
        color: $color-background;
      }
    }
  }
}