.widget-area {
  background-color: $color-principal;
  a {
    &:hover {
      color: $color-secundario;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 3rem;
    border-bottom: 3px dotted rgba($color:#fff, $alpha: 0.3);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: 0rem;
    }
  }
		&__item {
      color: $color-background;
      text-align: center;
      @media screen and (max-width: 575px) {
        margin-bottom: 1.5rem;
      }
		}
}

.logos-iso {


  &__line {
    display: flex;
    justify-content: center !important;
    align-items: flex-start;
    margin-bottom: 1rem;
  }


  &__v {
    width: 40px;
    height: auto;
    margin-right: 5px;
    border-radius: 5px;
    padding: .2rem;
    background-color: #fff;
  }

  &__h {
    width: 65px;
    height: auto;
    margin-right: 5px;
    border-radius: 5px;
    padding: .2rem;
    background-color: #fff;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    // display: grid;
    // grid-template-columns: 1fr;
    flex-direction: column;
  }
  &__copy {
    flex-direction: column;
    color: $color-background;
    @media screen and (max-width: 767px) {
      order: 1;
      text-align: center;
    }
    p {
      display: inline-block;
      margin-right: 0.7rem;
      display: block;
      width: 100%;
    }
    a {
      &:hover {
        color: $color-secundario;
      }
    }
  }
  &__asociados {
    margin-right: 1rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 3rem;
      margin-right: 0;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      align-items: center;
    }
    img {
      width: auto;
      height: 40px;
      margin-left: 2rem;
      opacity: .5;
      @media screen and (max-width: 991px) {
        margin-left: 0rem;
        margin-right: 1.5rem;
      }
      @media screen and (max-width: 575px) {
        margin-left: .5rem;
        margin-right: .5rem;
        height: 30px;
      }
      &:nth-child(2) {
        width: auto;
        height: 50px;
      }
    }
  }
}