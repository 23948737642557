.cta {
  background-color: $color-terciario;
  @media screen and (max-width: 575px) {
    padding: 3rem 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 8fr 3fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__info {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  &__titulo {
    font-size: 3rem;
    color: $color-principal;
    font-weight: bold;
    width: 70%;
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      font-size: 2rem;
      width: 100%;
      
    }
  }

  &__subtitulo {
    font-size: 1.3125rem;
    color: $color-secundario;
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1rem;
      margin-bottom: 0rem;
      color: $color-gris-04;
    }
  }



  &__action {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      align-items: center;
    }
  }
}
